import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  /* {
    path: '',
    loadChildren: () =>
      import("./views/ui/coming-soon/coming-soon.module").then(
        (mod) => mod.ComingSoonModule
      ),
  }, */
  {
    path: '',
    loadChildren: () =>
      import('./layouts/ui-layout/ui-layout.module').then(
        (mod) => mod.HomeLayoutModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
