<router-outlet></router-outlet>

<p-toast
  [breakpoints]="{
    '600px': { width: '90%', 'margin-inline': 'auto', right: '0', left: '0' }
  }"></p-toast>

<ngx-spinner type="none">
  <img
    src="../../../../../assets/images/flight-loader-without-bg.gif"
    alt=""
    width="200px" />
</ngx-spinner>
