import { environment } from '../../../environments/environment';

export class currentUser {
  _id?: string;
  title: string = 'Mr';
  genderType: string = 'M';
  dob: Date = new Date();
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phoneNumber: string = '';
  password: string = '';
  confirm_password: string = '';
  address1: string = '';
  street: string = '';
  state: any = '';
  country: string = '';
  city: string = '';
  mobile?: number;
  mobileNOCountryCode: any = 91;
}
