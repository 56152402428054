export const environment = {
  production: false,

  API_Supplier: 'https://devsupplierapi.hostnsell.com/api/',
  API_b2c: 'https://devfcapi.hostnsell.com/',
  API_EcoSave: 'https://devsaveapi.hostnsell.com/',
  PaymentAPI: 'https://devpaymentgatewayapi.hostnsell.com/PaymentAPI/',
  paymentRedirectUrl: 'https://devpaymentgatewayapi.hostnsell.com',
  baseUrl: 'https://devusermanagementapi.hostnsell.com/',
  cookieToken: 'ecoqazxsw2travel',
  cookieExpirationTime: 10,
  jwtTokenKey: 'echoTravel_JwtTken',
  currentUserKey: 'ecoTravel_currentUser',
  paymentStatus: `${location.origin}/paymentStatus`,
  CRMUrl: 'https://devcrmapi.hostnsell.com/api/TravelCRMService/',
  transferB2CBaseURI: 'https://devfcapi.hostnsell.com/api/',
  postTicketingURL: 'https://devcrmapi.hostnsell.com/api/PostTicketingService/',
  refundTicketingURL: 'https://devfcapi.hostnsell.com/api/PostTicketing/',
  shoppingURI: 'https://devancillaryadminapi.hostnsell.com/api/AncillaryDetails',
  timer: 1800, //600, // set 8 minutes time
  paxType: [
    {
      name: 'Adult',
      value: 'ADT',
      type: 'adultCount',
      paxAge: '12+ yrs',
      validAge: {
        from: 12,
        to: 120,
      },
    },
    {
      name: 'Child',
      value: 'CHD',
      type: 'childCount',
      paxAge: '2+ yrs',
      validAge: {
        from: 2,
        to: 12,
      },
    },
    {
      name: 'infant',
      value: 'INF',
      type: 'infantCount',
      paxAge: '0-2 yrs',
      validAge: {
        from: 0,
        to: 2,
      },
    },
  ],
  API_Hotels_BaseUrlFC: 'https://devfcapi.hostnsell.com/api/',
  Hotel_Base_URL: 'https://devfcapi.hostnsell.com/api/', //Temporary-BaseUrl
  API_Hotels_PgURL: 'https://devpaymentgatewayapi.hostnsell.com/api/PaymentAPI/',
  pickupDropPointBaseURI: 'https://devancillaryadminapi.hostnsell.com/api/',
  API_HotelSearch_Base_Url: 'https://devhotelserviceapi.hostnsell.com/api/',
  API_Hotels_PaymentStatus: `${location.origin}/hotel-booking-status`,
  Umrah_BookingStatus_Api_Base_Uri: `${location.origin}/umrah-booking-status`,
  package_BookingStatus_Api_Base_Uri: `${location.origin}/tour-booking-status`,
  BookingStatus_Api_Base_Uri: `${location.origin}`,
  baseRedirectURLPG: location.origin,
};
