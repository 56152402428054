import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { JwtService } from '..';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiBase = environment.baseUrl;
  headers: any = {};
  constructor(private httpClient: HttpClient, private jwtService: JwtService) {}

  post(url: string, param?: any): Observable<any> {
    const apiURL = url;
    // let headers = this.getHeader();
    return (
      this.httpClient
        // .post(apiURL, param, { headers, withCredentials: true })
        // .post(apiURL, param, { headers })
        .post(apiURL, param)
        .pipe(map((res) => res))
    );
  }

  put(url: string, param?: any): Observable<any> {
    const apiURL = url;
    // let headers = this.getHeader();
    return (
      this.httpClient.put(apiURL, param).pipe(map((res) => res))
    );
  }

  get(url: string): Observable<any> {
    const apiUrl = url;
    // let headers = this.getHeader();
    return this.httpClient.get(apiUrl).pipe(map((res) => res));
  }

  delete(url: string, param?: any): Observable<any> {
    // let headers = this.getHeader();
    const apiURL = url;
    return this.httpClient.post(apiURL, param).pipe(map((res) => res));
  }

  /*  getHeader() {
    let Token = this.jwtService.getToken()
    return new HttpHeaders({ 'Authorization': `Bearer ${Token}` });
  } */
}
