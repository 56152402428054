import { Component } from '@angular/core';
import { GlobalService } from './shared';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AlrajhiUmrah';

  constructor(
    private globalService: GlobalService,
    private translateService: TranslateService,
    private primengConfig: PrimeNGConfig,
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.setCurrencyOnPageReload();

    //Localization for primeng components
    //NOTE - To add support for all the components, we need to add the translation for each component in the languages json file.
    this.translateService.onLangChange.subscribe(() => {
      this.translateService
        .get('primeng')
        .subscribe((res) => this.primengConfig.setTranslation(res));
    });
  }

  setCurrencyOnPageReload() {
    const selectedCurrency = localStorage.getItem('wajedCurrency')
      ? JSON.parse(localStorage.getItem('wajedCurrency') as string).name
      : '';
    if (selectedCurrency !== '') {
      this.globalService.getROES().subscribe((resp: any) => {
        let v: any;
        v = selectedCurrency.hasOwnProperty('value')
          ? selectedCurrency.value.name
          : selectedCurrency;
        if (resp) {
          const selectedROE = resp?.rateOfExchange?.rateOfExchanges?.find(
            (roe: any) => roe.currencyCode === v,
          );
          if (selectedCurrency) {
            sessionStorage.setItem('ROE', JSON.stringify(selectedROE));

            const selectedBaseROE = resp?.rateOfExchange?.rateOfExchanges?.find(
              (roe: any) => roe.currencyCode === 'SAR',
            );

            if (selectedBaseROE) {
              sessionStorage.setItem('baseROE', JSON.stringify(selectedBaseROE));
            }
          }
        }
      });
    }
  }
}

export type ShoppingDropdownData = {
  city: string;
  country: string;
  countryCode: string;
  id: number;
  name: string;
  profileImage: string;
  profileImageArr: null | any[];
  profileImageBase64: null | string;
  serviceType?: string;
};
